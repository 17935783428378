import $ from "jquery";
import app from "./src/App";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

$(".menu-toggle").click(function () {
  app.mainMenu.toggle();
});

const $menu_items = $(".main-menu__display__item-contents > div");

$(".main-menu__item").hover(
  function () {
    let index = $(this).index();
    $menu_items.removeClass("show");
    $menu_items.eq(index).addClass("show");
  },
  function () {
    $menu_items.removeClass("show");
  },
);

function setViewportProperty(doc) {
  let prevClientHeight;
  let customVar = "--vh";

  function handleResize() {
    var clientHeight = doc.clientHeight;
    if (clientHeight === prevClientHeight) return;
    requestAnimationFrame(function updateViewportHeight() {
      doc.style.setProperty(customVar, clientHeight * 0.01 + "px");
      prevClientHeight = clientHeight;
    });
  }

  handleResize();
  return handleResize;
}

window.addEventListener(
  "resize",
  setViewportProperty(document.documentElement),
);

// Parallax effect GSAP
// $(".parallax-img").each(function () {
//   gsap.to($(this).find(".parallax-img__helper"), {
//     scrollTrigger: {
//       trigger: $(this),
//       start: "top 80%",
//       end: "bottom 20%",
//       scrub: 1.5,
//       invalidateOnRefresh: true,
//     },
//     top: () => {
//       return (
//         ($(this).find(".parallax-img__helper").outerHeight() -
//           $(this).height()) *
//         -1
//       );
//     },
//   });
// });

$(".mc4wp-form").submit(function (e) {
  e.preventDefault();
  $.ajax({
    method: "POST",
    url: "/",
    data: $(this).serialize(),
    dataType: "json",
    success: (response) => {
      let m = "";
      response.notices.forEach(function (n) {
        m += `<div class="mc4wp-alert mc4wp-${n.type}" role="alert"><p>${n.text}</p></div>`;
      });
      $(".mc4wp-response").empty().append($(m));
      if (response.success) {
        $(this).trigger("reset");
      }
    },
  });
});

// Function to handle intersection changes
function handleIntersection(entries, observer) {
  let hasLightSectionIntersecting = false;

  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      // Check if the intersecting section has the class indicating a light header theme
      if (entry.target.classList.contains("has-header-theme-light")) {
        hasLightSectionIntersecting = true;
      }
    }
  });

  // Remove the light class from the header if no intersecting section has the class
  if (!hasLightSectionIntersecting) {
    $(".site-header").removeClass("light");
  } else {
    // Add the light class if any intersecting section has the class
    $(".site-header").addClass("light");
  }
}

// Create a new Intersection Observer
const observer = new IntersectionObserver(handleIntersection, {
  root: null, // viewport
  rootMargin: `0px 0px -${window.innerHeight}px 0px`,
});

$(window).load(function () {
  // Observe all elements with the class .js-section
  document.querySelectorAll(".js-section").forEach((section) => {
    observer.observe(section);
  });
});

$(window).load(function () {
  if (window.location.hash !== "") {
    const hash = "section-" + window.location.hash.substring(1);
    console.log(hash);
    gsap.to(window, {
      duration: 1,
      scrollTo: {
        y: `#${hash}`,
        // offsetY: parseFloat(app.getCustomProperty("header-height")),
      },
    });
  }
});

let selfFadeEffect = (target) => {
  // fade in animation for each defined element
  const fadeIn = document.querySelectorAll(target);
  if (fadeIn.length) {
    fadeIn.forEach((el) => {
      const fadeTl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: "top 90%",
          end: "top 90%",
        },
      });
      fadeTl.fromTo(
        el,
        { yPercent: 5, opacity: 0, scale: 0.8 },
        {
          yPercent: 0,
          opacity: 1,
          scale: 1,
          duration: 1,
          ease: "ease",
        },
      );
    });
  }
};
selfFadeEffect(".js-fade__in--image");

let selfFadeEffectUp = (target) => {
  // fade in animation for each defined title element
  const fadeIn = document.querySelectorAll(target);
  if (fadeIn.length) {
    fadeIn.forEach((el) => {
      const fadeTl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: "top 90%",
          end: "top 90%",
        },
      });
      fadeTl.fromTo(
        el,
        { y: "5vh", opacity: 0 },
        { y: "0vh", opacity: 1, duration: 1, ease: "ease" },
      );
    });
  }
};
selfFadeEffectUp(".js-fade__in--up");

let childrenFadeEffectUpSequential = (target) => {
  // fade in animation for defined element's children in sequential order
  const fadeChildren = document.querySelectorAll(target);
  if (fadeChildren.length) {
    fadeChildren.forEach((el) => {
      const showTl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: "top 90%",
          end: "top 90%",
        },
      });
      showTl.fromTo(
        el.children,
        { y: "5vh", opacity: 0 },
        {
          y: "0vh",
          opacity: 1,
          duration: 1,
          stagger: 0.5,
          ease: "ease",
        },
      );
    });
  }
};
childrenFadeEffectUpSequential(".js-fade__in--up--children--sequential");

let childrenFadeEffectUp = (target) => {
  const fadeChildren = document.querySelectorAll(target);
  if (fadeChildren.length) {
    fadeChildren.forEach((el) => {
      gsap.to(el, {
        y: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: el,
          start: "top 90%",
          end: "top 90%",
          once: true,
          invalidateOnRefresh: true,
        },
      });
    });
  }
};

childrenFadeEffectUp(".js-fade__in--up--children > *");

let selfFadeEffectUpWines = (target) => {
  // fade in animation for each defined title element
  const fadeIn = document.querySelectorAll(target);
  if (fadeIn.length) {
    fadeIn.forEach((el) => {
      const fadeTl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: "top 75%",
          end: "top 75%",
        },
      });
      fadeTl.fromTo(
        el,
        { y: "10vh", opacity: 0 },
        { y: "0vh", opacity: 1, duration: 1, ease: "ease" },
      );
    });
  }
};
selfFadeEffectUpWines(".js-fade__in--wines");

$(window).scroll(function () {
  if (this.scrollY > $(".site-header").outerHeight()) {
    $(".site-header").addClass("sticky");
  } else {
    $(".site-header").removeClass("sticky");
  }
});
