import $ from "jquery";

/**
 *
 * @class MainMenu
 */
class MainMenu {
  isOpen = false;
  $body = $('header');

  /**
   * Opens the website main menu
   */
  open() {
    this.isOpen = true;
    this.$body.addClass('main-menu-is-open');
  }

  /**
   * Closes the website main menu
   */
  close() {
    this.isOpen = false;
    this.$body.removeClass('main-menu-is-open');
  }

  /**
   * Toggle the website main menu
   */
  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }
}

export default MainMenu;
